import React, { useEffect, useCallback, useState, useRef } from "react";
import ReactPlayer from "react-player";
import peer from "../service/peer";
import { useSocket } from "../context/SocketProvider";
import axios from "axios";
import { constant } from "../constant";

const RoomPage = () => {
  const socket = useSocket();
  const [remoteSocketId, setRemoteSocketId] = useState(null);
  const [myStream, setMyStream] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const [roomConnected, setRoomConnected] = useState(false);
  const [customerId, setcustomerId] = useState(0);
  
  const playerRef = useRef(null);
  let email;
  let room_id;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${constant.CLOUD_TOKEN}`,
  };
  //   const [email, setEmail] = useState("");
  // const [room, setRoom] = useState("");
  let idpMicrophoneId = "";
  let idpSpeakerId = "";
  let idpCameraId = "";
  let hdmiMicrophoneId = "";
  let hdmiSpeakerId = "";
  const IDP_MICROPHONE = "PCM2902 Audio Codec Analog Mono";
  const IDP_SPEAKER = "UACDemoV1.0 Analog Stereo";
  const IDP_CAMERA = "USB Camera (0c45:636b)";
  // const IDP_CAMERA = "Webcam Stream Name";
  const HDMI_MICROPHONE =
    "Microphone Array (Intel® Smart Sound Technology for Digital Microphones)";
  const HDMI_SPEAKER = "Speaker (Realtek(R) Audio)";
  // const HDMI_VIDEO_CAMERA ="USB Camera (0c45:636b)"

  // if hp laptop id idp and hdmi is hdmi
  // const IDP_MICROPHONE = "Microphone Array (Intel® Smart Sound Technology for Digital Microphones)"
  // const IDP_SPEAKER = "Speaker (Realtek(R) Audio)"
  // const IDP_CAMERA = "HP TrueVision HD Camera (0408:5365)"
  // const HDMI_MICROPHONE = "PCM2902 Audio Codec Analog Mono"
  // const HDMI_SPEAKER = "UACDemoV1.0 Analog Stereo"
  let customer_id = "";

  const handleUserJoined = useCallback(async ({ email, id, room }) => {
    console.log("Step 1 - handleUserJoined");
    console.log(`Email ${email} joined room`);
    console.log("room", room);

    // Use a regular expression to extract the number
    const match = room.match(/cus_(\d+)_cloud/);
    console.log("match", match);
    if (match) {
      customer_id = match[1]; // First capture group
      setcustomerId(match[1])
      console.log("customer_id", customer_id);
    } else {
      console.log("No customer_id found");
    }

    // if status is 1 then busy else next
    await axios
      .post(
        `${constant.CLOUD_API_URL}/getWebrtcStatus`,
        { customer_id },
        { headers: headers }
      )
      // Print data
      .then((response) => {
        console.log("message", response.data.message);
        console.log("response", response.data.Data);
        if (response.data.Data[0].busy == "0") {
          if (email !== "idp@gmail.com") {
            setRemoteSocketId(id);
          } else {
            setRoomConnected(true);
          }
        } else {
          console.log("Busy", response.data.Data[0].busy);
          socket.emit("user:busy", { room: id });
        }
      })
      // Print error message if occur
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  // {
  // deviceId:{
  //   exact:"0f04815fa6e08ab09c08f1f92c0ce3d9df857ef1d9ebd40fd889b61e57aca70f"
  // }
  // }

  const handleCallUser = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
      // audio: {
      //   deviceId: {
      //     exact: idpMicrophoneId, //MICROPHONE
      //   },
      // },
      // video: {
      //   deviceId: {
      //     exact: idpCameraId, //CAMERA
      //   },
      // },
    });
    const offer = await peer.getOffer();
    socket.emit("user:call", { to: remoteSocketId, offer });
    setMyStream(stream);
  }, [remoteSocketId, socket]);

  const handleIncommingCall = useCallback(
    async ({ from, offer }) => {
      console.log("Step 2 - handleIncommingCall");
      setRemoteSocketId(from);
      const stream = await navigator.mediaDevices.getUserMedia({
        // audio: {
        //   deviceId: {
        //     exact: hdmiMicrophoneId, //MICROPHONE
        //   },
        // },
        audio: true,
        video: false,
      });
      setMyStream(stream);
      console.log(`Incoming Call`, from, offer);
      const ans = await peer.getAnswer(offer);
      socket.emit("call:accepted", { to: from, ans });
    },
    [socket]
  );

  const onReady = useCallback(() => {
    console.log("onReady");
  }, []);

  // const handleAudioToggle = useCallback(async () => {
  //   console.log("handleAudioToggle");

  //   var myVideo = document.querySelector("#main video");
  //   console.log("myVideo ", myVideo);
  //   // myVideo.setSinkId(
  //   //   location.state.idpSpeakerId === "" ? location.state.hdmiSpeakerId : location.state.idpSpeakerId//Speaker
  //   // );
  // }, []);

  const sendStreams = useCallback(() => {
    for (const track of myStream.getTracks()) {
      track.enabled = true;
      peer.peer.addTrack(track, myStream);
    }
  }, [myStream]);

  const handleCallAccepted = useCallback(
    ({ from, ans }) => {
      console.log("Step 3 - handleCallAccepted");
      peer.setLocalDescription(ans);
      console.log("Call Accepted!");
      sendStreams();
    },
    [sendStreams]
  );

  const handleNegoNeeded = useCallback(async () => {
    const offer = await peer.getOffer();
    console.log("------------",customerId);
    socket.emit("peer:nego:needed", { offer, to: remoteSocketId,customer_id:customerId });
  }, [remoteSocketId, socket]);

  useEffect(() => {
    peer.peer.addEventListener("negotiationneeded", handleNegoNeeded);
    return () => {
      peer.peer.removeEventListener("negotiationneeded", handleNegoNeeded);
    };
  }, [handleNegoNeeded]);

  const handleNegoNeedIncomming = useCallback(
    async ({ from, offer,customer_id }) => {
      console.log("handleNegoNeedIncomming",customer_id);
      console.log("Step 4 - handleNegoNeedIncomming");
      const ans = await peer.getAnswer(offer);
      socket.emit("peer:nego:done", { to: from, ans });

      const data = {
        flag: '1',
        customer_id:customer_id,
      };

      await axios
        .post(`${constant.CLOUD_API_URL}/updateWebrtcStatus`, data, {
          headers: headers,
        })
        // Print data
        .then((response) => {
          console.log("message", response.data.message);
          console.log("response", response.data.Data);
        })
        // Print error message if occur
        .catch((error) => {
          console.log("error", error);
        });
    },
    [socket]
  );

  const handleNegoNeedFinal = useCallback(async ({ ans }) => {
    console.log("Step 5 - handleNegoNeedFinal");
    await peer.setLocalDescription(ans);
  }, []);

  const handleRoomLeft = useCallback(
    async ({ email, room }) => {
      console.log("Room Left");
      console.log("email", email);
      console.log("room", room);

      // Use a regular expression to extract the number
      const match = room.match(/cus_(\d+)_cloud/);
      console.log("match", match);
      if (match) {
        customer_id = match[1]; // First capture group
        console.log("customer_id", customer_id);
      } else {
        console.log("No customer_id found");
      }

      const data = {
        flag: '0',
        customer_id,
      };

      await axios
        .post(`${constant.CLOUD_API_URL}/updateWebrtcStatus`, data, {
          headers: headers,
        })
        // Print data
        .then((response) => {
          console.log("message", response.data.message);
          console.log("response", response.data.Data);
        })
        // Print error message if occur
        .catch((error) => {
          console.log("error", error);
        });
    },
    [socket]
  );

  useEffect(() => {
    peer.peer.addEventListener("track", async (ev) => {
      const remoteStream = ev.streams;
      console.log("GOT TRACKS!!");
      setRemoteStream(remoteStream[0]);
    });
  }, []);
  const handleJoin = () => {
    setRoomConnected(true);
  };
  useEffect(() => {
    console.log("Called 1");
    handleInputDevices();
    socket.on("room:join", handleJoin);
    socket.on("user:joined", handleUserJoined);
    socket.on("incomming:call", handleIncommingCall);
    socket.on("call:accepted", handleCallAccepted);
    socket.on("peer:nego:needed", handleNegoNeedIncomming);
    socket.on("peer:nego:final", handleNegoNeedFinal);
    socket.on("room:left", handleRoomLeft);
    return () => {
      socket.on("room:join", handleJoin);
      socket.off("user:joined", handleUserJoined);
      socket.off("incomming:call", handleIncommingCall);
      socket.off("call:accepted", handleCallAccepted);
      socket.off("peer:nego:needed", handleNegoNeedIncomming);
      socket.off("peer:nego:final", handleNegoNeedFinal);
      socket.off("room:left", handleRoomLeft);
    };
  }, [
    socket,
    handleUserJoined,
    handleIncommingCall,
    handleCallAccepted,
    handleNegoNeedIncomming,
    handleNegoNeedFinal,
    handleRoomLeft,
    handleJoin,
  ]);

  const handleInputDevices = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    let devices = await navigator.mediaDevices.enumerateDevices();
    console.log("devices: ", devices);

    // devices.forEach((device) => {
    //   if (device.kind == "audioinput" && device.label == IDP_MICROPHONE) {
    //     idpMicrophoneId = device.deviceId;
    //   } else if (device.kind == "audiooutput" && device.label == IDP_SPEAKER) {
    //     idpSpeakerId = device.deviceId;
    //   } else if (device.kind == "videoinput" && device.label == IDP_CAMERA) {
    //     idpCameraId = device.deviceId;
    //   } else if (device.kind == "audioinput" && device.label == HDMI_MICROPHONE) {
    //     hdmiMicrophoneId = device.deviceId;
    //   } else if (device.kind == "audiooutput" && device.label == HDMI_SPEAKER) {
    //     hdmiSpeakerId = device.deviceId;
    //   }
    // });

    // console.log(
    //   "+++++++++++++++++++++++++++Input Devices++++++++++++++++++++++"
    // );
    // devices.forEach((element) => {
    //   if (element.kind == "audioinput") {
    //     console.log("Name: ", element.label);
    //     console.log("ID: ", element.deviceId);
    //   }
    // });

    // console.log(
    //   "+++++++++++++++++++++++++++Output Devices++++++++++++++++++++++"
    // );

    // devices.forEach((element) => {
    //   if (element.kind == "audiooutput") {
    //     console.log("Name: ", element.label);
    //     console.log("ID: ", element.deviceId);
    //   }
    // });

    // console.log(
    //   "+++++++++++++++++++++++++++Video Camera Devices++++++++++++++++++++++"
    // );

    // devices.forEach((element) => {
    //   if (element.kind == "videoinput") {
    //     console.log("Name: ", element.label);
    //     console.log("ID: ", element.deviceId);
    //   }
    // });
  };

  const handleEndCall = () => {
    // const email = location.state.email;
    // const room = location.state.room;
    console.log(
      "email----------------))",
      document.getElementById("email").value
    );
    console.log(
      "room_id--------------))",
      document.getElementById("room_id").value
    );
    email = document.getElementById("email").value;
    room_id = document.getElementById("room_id").value;
    socket.emit("leave:room", { email, room: room_id });
    peer.peer.close();
    peer.peer = null;
  };

  // useEffect(() => {
  //   console.log("Called 2");
  //   handleRoomJoin();
  // }, [socket]);

  const handleRoomJoin = () => {
    console.log(document.getElementById("email").value);
    console.log(document.getElementById("room_id").value);
    email = document.getElementById("email").value;
    room_id = document.getElementById("room_id").value;
    if (!roomConnected) {
      console.log("Room not connected");
      socket.emit("room:join", { email, room: room_id });
    } else {
      console.log("Connected to room");
    }
  };

  return (
    <div id="main">
      {/* <p id="email"></p>
      <p id="room_id"></p> */}
      <label htmlFor="email">Email</label>
      <input
        type="email"
        id="email"
        // value={email}
        // onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <label htmlFor="room">Room Number</label>
      <input
        type="text"
        id="room_id"
        // value={room}
        // onChange={(e) => setRoom(e.target.value)}
      />
      <button id="join_room" onClick={handleRoomJoin}>
        Join Room{" "}
      </button>

      <h4>
        {roomConnected
          ? remoteSocketId
            ? "Connected"
            : "No one in room"
          : "Connect to Room"}
      </h4>
      <button id="endcall" onClick={handleEndCall}>
        End Call
      </button>

      {myStream && (
        <button id="accept" onClick={sendStreams}>
          Accept Call
        </button>
      )}
      {remoteSocketId && (
        <button id="call" onClick={handleCallUser}>
          CALL
        </button>
      )}

      {remoteStream && (
        <>
          <h1>IDP Camera View</h1>
          <ReactPlayer
            playing
            ref={playerRef}
            onReady={onReady}
            muted={false}
            height="200px"
            width="400px"
            url={remoteStream}
            style={{ margin: "auto", width: "50%" }}
          />
        </>
      )}
    </div>
  );
};

export default RoomPage;
