class PeerService {

    constructor() {
  //  if (!this.peer) {                                          // Normal Code
  //     this.peer = new RTCPeerConnection({
  //       // iceServers: [
  //       //   {
  //       //     "urls": "stun:stun.l.google.com:19302"
  //       //   }
  //       // ],
  //     });
  //   }

    if (!this.peer) {                                              // TURN Server
      this.peer = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:44.225.196.243:3478",
          },
        ],
      });
    }
    
  }


  async getAnswer(offer) {
    if (this.peer) {
      await this.peer.setRemoteDescription(offer);
      const ans = await this.peer.createAnswer();
      await this.peer.setLocalDescription(new RTCSessionDescription(ans));
      return ans;
    }
  }

  async setLocalDescription(ans) {
    if (this.peer) {
      await this.peer.setRemoteDescription(new RTCSessionDescription(ans));
    }
  }

  async getOffer() {
    if (this.peer) {
      const offer = await this.peer.createOffer();
      await this.peer.setLocalDescription(new RTCSessionDescription(offer));
      return offer;
    }
  }
}

export default new PeerService();
