import { Routes, Route } from "react-router-dom";
import RoomPage from "./screens/Room";

function App() {
  return (
    <div className="App" style={{ textAlign: "center" }}>
      <Routes>
        <Route path="/" element={<RoomPage />} />
      </Routes>
    </div>
  );
}

export default App;